import React, { useEffect, useState, useContext } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import '../CSS/ConfirmPhrasePage.css';
import { ethers } from 'ethers';
import { globalContext } from '../GlobalStates/globalContext';

function ConfirmPhrasePage({ navigateTo, mnemonic }) {
  const [selectedWords, setSelectedWords] = useState(Array(mnemonic.split(' ').length).fill(''));
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const globalState = useContext(globalContext);
  

  const handleWordClick = (word, index) => {
    const selectedIndex = selectedIndexes.indexOf(index);
    if (selectedIndex !== -1) {
      const newSelection = [...selectedWords];
      const wordPosition = newSelection.indexOf(word);
      newSelection[wordPosition] = '';
      setSelectedWords(newSelection);
      setSelectedIndexes(selectedIndexes.filter(i => i !== index));
    } else {
      const emptyIndex = selectedWords.findIndex(w => w === '');
      if (emptyIndex !== -1) {
        const newSelection = [...selectedWords];
        newSelection[emptyIndex] = word;
        setSelectedWords(newSelection);
        setSelectedIndexes([...selectedIndexes, index]);
      }
    }
  };

  const handleSubmit = () => {
    if (JSON.stringify(selectedWords) === JSON.stringify(mnemonic.split(' '))) {
      const newWallet = ethers.Wallet.fromPhrase(mnemonic);
      console.log("Wallet created successfully:", newWallet);
      
    } else {
      globalState.setShowError({
        status : true,
        message : "The words are in the wrong order. Please try again."
      })
    }
  };

  return (
    <div className="confirm-phrase-container">
      <div className="top-bar">
        <FaArrowLeft onClick={() => navigateTo('CreateWallet', mnemonic)} className="back-icon" />
        <span>Back</span>
      </div>
      <h2>Confirm Secret Recovery Phrase</h2>
      <p>Select each word in the correct order</p>

      <div className="word-grid">
        {selectedWords.map((word, index) => (
          <div key={index} className="input-group">
            <label>{index + 1}. {word || ' '}</label>
          </div>
        ))}
      </div>

      <div className="word-grid">
        {mnemonic.split(' ').map((word, index) => (
          <button
            key={index}
            onClick={() => handleWordClick(word, index)}
            className={`word-button  ${selectedIndexes.includes(index) ? 'selected' : ''}`}
          >
            {word}
          </button>
        ))}
      </div>

      <button className='new-wallet-submit-button' onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default ConfirmPhrasePage;
