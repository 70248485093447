import { CHAIN_NAMESPACES } from "@web3auth/base";

export const networkConfigs = {
  sepolia: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0xaa36a7", // Sepolia Testnet
    rpcTarget: "https://sepolia.infura.io/v3/c989f13cf6de48ed9b5118328340d037",
    displayName: "Sepolia Testnet",
    blockExplorerUrl: "https://sepolia.etherscan.io",
    ticker: "ETH",
    tickerName: "Ethereum",
  },
  mainnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x1", // Ethereum Mainnet
    rpcTarget: "https://mainnet.infura.io/v3/c989f13cf6de48ed9b5118328340d037",
    displayName: "Ethereum Mainnet",
    blockExplorerUrl: "https://etherscan.io",
    ticker: "ETH",
    tickerName: "Ethereum",
  },
  polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x89", // Polygon Mainnet
    rpcTarget: "https://polygon-rpc.com",
    displayName: "Polygon",
    blockExplorerUrl: "https://polygonscan.com",
    ticker: "MATIC",
    tickerName: "Polygon",
  },
  amoy: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x13882", // Polygon Mainnet
    rpcTarget: "https://rpc-amoy.polygon.technology/",
    displayName: "Polygon Amoy Testnet",
    blockExplorerUrl: " https://amoy.polygonscan.com/",
    ticker: "MATIC",
    tickerName: "Polygon Amoy",
  },
  holesky: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x4268",
    rpcTarget : "https://holesky.infura.io/v3/c989f13cf6de48ed9b5118328340d037",
    displayName: "Holesky",
    blockExplorerUrl: "https://explorer.holesky.dev",
    ticker: "ETH",
    tickerName: "Ethereum",
  },
};
