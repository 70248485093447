import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import {web3authInstance} from '../web3auth_config/web3authConfig'
import Web3 from 'web3'; 
import { globalContext } from '../GlobalStates/globalContext';
import { useNavigate } from 'react-router-dom';
import '../CSS/Login.css'

function Login() {
  const globalState = useContext(globalContext);
  const navigate = useNavigate()

  useLayoutEffect(()=>{
    const auth_store = JSON.parse(localStorage.getItem("auth_store"))
    if(auth_store?.sessionId){
      navigate('/home')
    }
  })


  const login = async () => {
    try {
      const web3auth = await web3authInstance(globalState.selectedNetwork)
      globalState.setWeb3auth(web3auth)
      if (!web3auth.provider) {
        await web3auth.initModal();
      }
      if (!web3auth.connected) {
        const provider = await web3auth.connect();
        const web3Instance = new Web3(provider);  
        const accounts = await web3Instance.eth.getAccounts(); 
        const userAddress = accounts[0];
        const userProfile = await web3auth.getUserInfo();
        globalState.setUser({ address: userAddress, profile: userProfile });
        globalState.setCurrentAccountAddress(userAddress)
        globalState.setGlobalProvider(provider);
        navigate('/home');
      }
    } catch (error) {
      console.error('Login failed', error);
      globalState.setShowError({
        status : true,
        message : error?.message
      })
    }
  };



  return (
    <>
    <div className='login-container'>
            {
             
                <button className='login-button' onClick={login}>Continue with Web3Auth </button>
            }
        </div>
      
        </>
  );
}

export default Login