import "./App.css";
import Login from "./Components/Login";
import Home from "./Components/Home";
import GlobalStates from "./GlobalStates/globalStates";
import { Routes, Route, useLocation } from "react-router-dom";
import CreateWallet from "./Components/CreateWallet";
import ImportWallet from "./Components/ImportWallet";
import ConfirmPhrasePage from "./Components/ConfirmPhrasePage";
// import Profile from './Components/Profile';
import Profile from "./Components/Profile";
import Footer from "./Components/Footer";
import { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { globalContext } from "./GlobalStates/globalContext";
import { web3authInstance } from "./web3auth_config/web3authConfig";
import AddWallet from "./Components/AddWallet";
import ErrorAlertBox from "./Components/ErrorAlertBox";
// import Footer from './Components/Footer';

function App() {
  const globalState = useContext(globalContext);
  const location = useLocation()

  const connectToWeb3Auth = async () => {
    try {
      const auth_store = JSON.parse(localStorage.getItem("auth_store"));
      // if (!globalState.web3auth) {
        const web3auth = await web3authInstance(globalState.selectedNetwork);
        globalState.setWeb3auth(web3auth);
      // }
        if (auth_store?.sessionId) {
          await web3auth.initModal();
          await web3auth.connect();
          if (web3auth.connected) {
            const provider = web3auth.provider;
            const web3Instance = new Web3(provider);
            const accounts = await web3Instance.eth.getAccounts();
            const userAddress = accounts[0];
            const balance = await web3Instance.eth.getBalance(userAddress);
            globalState.setCurrentAccountAddress(accounts[0]);
            const userProfile = await web3auth.getUserInfo();
            globalState.setUser({ address: userAddress, profile: userProfile });
            globalState.setGlobalProvider(provider);
          }
        }
    } catch (error) {
      console.error("Failed initializing Web3Auth:", error);
    }
  };
  useEffect(() => {
    connectToWeb3Auth();
  }, [globalState.selectedNetwork]);

  return (
    <div className="page-container">
      <ErrorAlertBox show={globalState.showError} setShow={globalState.setShowError} />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/addWallet" element={<AddWallet />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>

      {location.pathname === '/' ? null : <Footer />}
    </div>
  );
}

export default App;
