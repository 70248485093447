import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { networkConfigs } from "./chainConfig";


// const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0xaa36a7",
//   rpcTarget: "https://sepolia.infura.io/v3/c989f13cf6de48ed9b5118328340d037",
//   displayName: "Ethereum Sepolia Testnet",
//   blockExplorerUrl: "https://sepolia.etherscan.io",
//   ticker: "ETH",
//   tickerName: "Ethereum",
//   logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
// };

// const privateKeyProvider = new EthereumPrivateKeyProvider({
//   config: { chainConfig },
// });

// const web3AuthOptions = {
//   clientId:
//     "BCL5DAFh2EpBZv1mO8MmjMgzERCqFQulsyFZj_g2Oc0CgdfVquTPSilLO5msEu4cPfqa2tY8lpHPvEX7E5hK06M",
//   web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
//   privateKeyProvider,
// };

// export const web3auth = new Web3Auth(web3AuthOptions);

export const web3authInstance = async (selectedNetwork) => {

  const chainConfig = networkConfigs[selectedNetwork || "sepolia"]

  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
  });

  const web3AuthOptions = {
    clientId:
      "BCL5DAFh2EpBZv1mO8MmjMgzERCqFQulsyFZj_g2Oc0CgdfVquTPSilLO5msEu4cPfqa2tY8lpHPvEX7E5hK06M",
    web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
    privateKeyProvider,
  };

  const web3auth = new Web3Auth(web3AuthOptions);

  return web3auth;
};
