import React, { useState } from 'react'
import '../CSS/footer.css'
import { Link } from 'react-router-dom'
import ScanQrModal from './ScanQrModal'

function Footer() {
  const [showScanner, setShowScanner] = useState(false)
  return (
         <div className="bottom-nav">
          <ScanQrModal showScanner={showScanner} setShowScanner={setShowScanner}/>
        <div className="nav-icons">
          <Link to={'/home'} className="nav-icon">
            <i className="bi bi-house"></i>
          </Link>
          <button onClick={()=>{setShowScanner(true)}} className="nav-icon active button"><i className="bi bi-qr-code-scan"></i></button>
          <Link to={'/profile'} className="nav-icon">
            <i className="bi bi-person-circle"></i>
          </Link>
        </div>
      </div>
  )
}

export default Footer