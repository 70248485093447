import React, { useContext, useState } from 'react';
import { ethers } from 'ethers';
import { generateMnemonic } from 'bip39';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import '../CSS/ImportWallet.css'; 
import Create from '../Svg/CreateSvg';
import Import from '../Svg/ImportSvg';
import Footer from './Footer';
import { globalContext } from '../GlobalStates/globalContext';

function ImportWallet({ navigateTo }) {
  const globalState = useContext(globalContext);
  const [show, setShow] = useState(false);
  const [importType, setImportType] = useState('Private Key');
  const [privateKey, setPrivateKey] = useState('');
  const [mnemonicWords, setMnemonicWords] = useState(Array(12).fill(''));
  const [walletAddress, setWalletAddress] = useState(null);
  const [mnemonic, setMnemonic] = useState('');


  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setImportType('Private Key');
    setPrivateKey('');
    setMnemonicWords(Array(12).fill(''));
  };

  const handleSelect = (type) => {
    setImportType(type);
    if (type === 'Private Key') {
      setMnemonicWords(Array(12).fill(''));
    } else {
      setPrivateKey('');
    }
  };

  const handleConfirm = () => {
    try {
      let wallet;
      if (importType === 'Private Key') {
        wallet = new ethers.Wallet(privateKey);
      } else {
        const mnemonicPhrase = mnemonicWords.join(' ');
        wallet = ethers.Wallet.fromMnemonic(mnemonicPhrase);
        console.log('Mnemonic Phrase:', mnemonicPhrase);
      }
      setWalletAddress(wallet.address);
    } catch (error) {
      console.log(error);
      globalState.setShowError({
        status : true,
        message : importType === 'Private Key' ? 'Invalid Private Key' : 'Invalid Mnemonic Phrase'
      })
    }
    handleClose();
  };


  const handleCreateWallet = () => {
    const mnemonic = generateMnemonic(128);
    setMnemonic(mnemonic);
    navigateTo('CreateWallet', mnemonic); 
  };

 
  const handleMnemonicChange = (index, value) => {
    const newMnemonicWords = [...mnemonicWords];
    newMnemonicWords[index] = value;
    setMnemonicWords(newMnemonicWords);
  };

  return (
    <div className="import-wallet-container">
      <div className="import-buttons">
        <button onClick={handleShow}>
          <Import />
          <br />
          <span>Import Existing Wallet</span>
        </button>
        <button onClick={handleCreateWallet} className="secondary">
          <Create />
          <br />
          <span>Create New Wallet</span>
        </button>
      </div>

     
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Import Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#5C3FD1', borderColor: '#5C3FD1' }}>
              {importType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSelect('Private Key')}>Private Key</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect('Mnemonic Phrase')}>Mnemonic Phrase</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {importType === 'Private Key' && (
            <div className="mt-3">
              <label htmlFor="privateKey">Enter Private Key:</label>
              <input
                type="text"
                className="form-control"
                value={privateKey}
                placeholder="Enter Private Key"
                onChange={(e) => setPrivateKey(e.target.value)}
              />
            </div>
          )}

          {importType === 'Mnemonic Phrase' && (
            <div className="mt-3">
              <label htmlFor="mnemonicPhrase">Enter 12 Words Mnemonic Phrase:</label>
              <div className="mnemonic-grid">
                {mnemonicWords.map((word, index) => (
                  <input
                    key={index}
                    type="text"
                    className="form-control"
                    value={word}
                    placeholder={`Word ${index + 1}`}
                    onChange={(e) => handleMnemonicChange(index, e.target.value)}
                  />
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleConfirm} style={{ backgroundColor: '#5C3FD1', borderColor: '#5C3FD1' }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {walletAddress && (
        <div className="wallet-address">
          <h5>Imported Wallet Address:</h5>
          <p>{walletAddress}</p>
        </div>
      )}
      
    </div>
  );
}

export default ImportWallet;
