import React from 'react'

function Create() {
  return (
    <svg 
      viewBox="0 0 512 512" 
      fill="#000000" 
      height="38px" 
      width="38px" 
      version="1.1" 
      id="Layer_1"
    >
<g>
	<g>
		<path d="M303.904,376h-39.86v-39.648c0-6.576-5.384-11.928-12-11.928c-6.616,0-12,5.348-12,11.928V376h-39.896    c-6.58,0-11.932,5.384-11.932,12c0,6.616,5.352,12,11.932,12h39.896v40.112c0,6.576,5.384,11.928,12,11.928    c6.616,0,12-5.352,12-11.928V400h39.86c6.58,0,11.932-5.384,11.932-12C315.836,381.384,310.484,376,303.904,376z M303.904,392    h-43.86c-2.208,0-4,1.792-4,4v44.112c0,2.164-1.796,3.928-4,3.928c-2.168,0-4-1.796-4-3.928V396c0-2.208-1.792-4-4-4h-43.896    c-2.168,0-3.932-1.796-3.932-4c0-2.204,1.764-4,3.932-4h43.896c2.208,0,4-1.792,4-4v-43.648c0-2.128,1.832-3.928,4-3.928    c2.204,0,4,1.76,4,3.928V380c0,2.208,1.792,4,4,4h43.86c2.168,0,3.932,1.796,3.932,4C307.836,390.204,306.072,392,303.904,392z"/>
	</g>
</g>
<g>
	<g>
		<path d="M140.044,384H63.46c-12.912,0-23.416-10.412-23.416-23.204V88c0-2.208-1.792-4-4-4s-4,1.792-4,4v272.796    C32.044,378,46.136,392,63.46,392h76.584c2.208,0,4-1.792,4-4S142.252,384,140.044,384z"/>
	</g>
</g>
<g>
	<g>
		<path d="M452.044,260c-2.208,0-4,1.792-4,4v96.796c0,12.576-11.236,23.204-24.532,23.204h-59.468c-2.208,0-4,1.792-4,4    s1.792,4,4,4h59.468c17.632,0,32.532-14.292,32.532-31.204V264C456.044,261.792,454.252,260,452.044,260z"/>
	</g>
</g>
<g>
	<g>
		<path d="M423.512,60H64.044c-2.208,0-4,1.792-4,4s1.792,4,4,4h359.468c13.296,0,24.532,10.644,24.532,23.244V184    c0,2.208,1.792,4,4,4s4-1.792,4-4V91.244C456.044,74.308,441.144,60,423.512,60z"/>
	</g>
</g>
<g>
	<g>
		<path d="M423.512,44H68.044c-2.208,0-4,1.792-4,4s1.792,4,4,4h355.468c13.068,0,24.532,11.02,24.532,23.588V164    c0,2.208,1.792,4,4,4s4-1.792,4-4V75.588C456.044,58.464,441.144,44,423.512,44z"/>
	</g>
</g>
<g>
	<g>
		<path d="M63.46,59.816c-12.92,0-23.428-10.512-23.428-23.432c0-2.208-1.792-4-4-4c-2.208,0-4,1.792-4,4    c0,17.332,14.096,31.432,31.428,31.432c2.208,0,4-1.792,4-4S65.668,59.816,63.46,59.816z"/>
	</g>
</g>
<g>
	<g>
		<path d="M63.46,100.1c-12.92,0-23.428-10.508-23.428-23.428c0-2.208-1.792-4-4-4c-2.208,0-4,1.792-4,4    C32.032,94,46.128,108.1,63.46,108.1c2.208,0,4-1.792,4-4S65.668,100.1,63.46,100.1z"/>
	</g>
</g>
<g>
	<g>
		<path d="M389.224,0H63.46C46.136,0,32.044,14.096,32.044,31.42V304c0,2.208,1.792,4,4,4s4-1.792,4-4V31.42    C40.044,18.504,50.548,8,63.46,8h325.764c12.796,0,22.82,10.288,22.82,23.42V44c0,2.208,1.792,4,4,4s4-1.792,4-4V31.42    C420.044,14.096,406.216,0,389.224,0z"/>
	</g>
</g>
<g>
	<g>
		<path d="M421.388,43.512C419.444,28.084,405.316,16,389.224,16H68.044c-2.208,0-4,1.792-4,4s1.792,4,4,4h321.18    c11.932,0,22.804,9.2,24.224,20.512c0.256,2.02,1.976,3.5,3.964,3.5c0.168,0,0.336-0.012,0.508-0.032    C420.112,47.704,421.664,45.704,421.388,43.512z"/>
	</g>
</g>
<g>
	<g>
		<path d="M454.328,180H325.764c-9.772,0-17.72,7.952-17.72,17.724v52.552c0,9.772,7.948,17.724,17.72,17.724h128.56    c9.772,0,17.72-7.952,17.724-17.72v-52.556C472.048,187.952,464.096,180,454.328,180z M464.048,250.276    c0,5.364-4.36,9.724-9.72,9.724H325.764c-5.36,0-9.72-4.36-9.72-9.72v-52.556c0-5.364,4.36-9.724,9.72-9.724h128.564    c5.36,0,9.72,4.364,9.72,9.724V250.276z"/>
	</g>
</g>
<g>
	<g>
		<path d="M352.208,196.084c-15.44,0-28.004,12.56-28.004,28c0,15.444,12.564,28.008,28.004,28.008s28.004-12.564,28.004-28.008    C380.212,208.644,367.648,196.084,352.208,196.084z M352.208,244.092c-11.028,0-20.004-8.976-20.004-20.008    c0-11.028,8.976-20,20.004-20s20.004,8.972,20.004,20C372.212,235.116,363.236,244.092,352.208,244.092z"/>
	</g>
</g>
<g>
	<g>
		<path d="M360.076,220.088c-2.208,0-4,1.792-4,4c0,2.192-1.784,3.976-3.976,3.976s-3.976-1.784-3.976-3.976    c0-2.192,1.784-3.972,3.976-3.972c2.208,0,4-1.792,4-4c0-2.208-1.792-4-4-4c-6.604,0-11.976,5.372-11.976,11.972    c0,6.604,5.372,11.976,11.976,11.976s11.976-5.372,11.976-11.976C364.076,221.88,362.284,220.088,360.076,220.088z"/>
	</g>
</g>
<g>
	<g>
		<path d="M148.044,344H63.46c-12.724,0-22.688-10.288-22.688-23.428c0-2.208-1.792-4-4-4c-2.208,0-4,1.792-4,4    c0,17.624,13.48,31.428,30.688,31.428h84.584c2.208,0,4-1.792,4-4S150.252,344,148.044,344z"/>
	</g>
</g>
<g>
	<g>
		<path d="M408.044,260c-2.208,0-4,1.792-4,4v77.064c0,1.512-1.544,2.936-3.176,2.936h-44.824c-2.208,0-4,1.792-4,4s1.792,4,4,4    h44.824c6.06,0,11.176-5.008,11.176-10.936V264C412.044,261.792,410.252,260,408.044,260z"/>
	</g>
</g>
<g>
	<g>
		<path d="M401.2,100H64.044c-2.208,0-4,1.792-4,4s1.792,4,4,4H401.2c1.54,0,2.844,1.36,2.844,2.964V164c0,2.208,1.792,4,4,4    s4-1.792,4-4v-53.036C412.044,104.916,407.18,100,401.2,100z"/>
	</g>
</g>
<g>
	<g>
		<path d="M66.1,44.152c-5.488,0-9.952-4.468-9.952-9.96c0-5.488,4.464-9.956,9.952-9.956c2.208,0,4-1.792,4-4s-1.792-4-4-4    c-9.896,0-17.952,8.052-17.952,17.956S56.2,52.152,66.1,52.152c2.208,0,4-1.792,4-4S68.308,44.152,66.1,44.152z"/>
	</g>
</g>
<g>
	<g>
		<path d="M252.112,272.384c-63.876,0-115.844,51.968-115.844,115.844c0,63.88,51.968,115.852,115.844,115.852    s115.844-51.972,115.844-115.852C367.956,324.352,315.988,272.384,252.112,272.384z M252.112,496.08    c-59.464,0-107.844-48.384-107.844-107.852c0-59.464,48.38-107.844,107.844-107.844c59.464,0,107.844,48.38,107.844,107.844    C359.956,447.696,311.576,496.08,252.112,496.08z"/>
	</g>
</g>
<g>
	<g>
		<path d="M330.076,325.388c-1.392-1.72-3.916-1.984-5.628-0.592c-1.716,1.392-1.98,3.912-0.592,5.628    c13.184,16.284,20.444,36.816,20.444,57.812c0,50.696-41.248,91.94-91.944,91.94s-91.944-41.244-91.944-91.94    c0-50.696,41.244-91.94,91.944-91.94c18.044,0,35.504,5.216,50.5,15.092c1.844,1.216,4.324,0.704,5.54-1.14    c1.216-1.844,0.704-4.324-1.14-5.54c-16.304-10.74-35.288-16.416-54.9-16.416c-55.108,0-99.944,44.832-99.944,99.94    c0,55.108,44.836,99.94,99.944,99.94c55.108,0,99.944-44.832,99.948-99.94C352.304,365.408,344.408,343.092,330.076,325.388z"/>
	</g>
</g>
</svg>
  )
}

export default Create