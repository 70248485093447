import React from 'react';

function Import() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="38px" width="38px" version="1.1" id="Layer_1" viewBox="0 0 512 512" stroke="white">
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <g>
        <path d="M456.002,380c-2.208,0-4,1.792-4,4v96.436c0,12.772-10.952,23.564-23.916,23.564H68.026 c-13.024,0-24.028-10.792-24.028-23.564V208c0-2.208-1.792-4-4-4s-4,1.792-4,4v272.436c0,17.112,14.668,31.564,32.028,31.564 h360.056c17.3,0,31.916-14.452,31.92-31.564V384C460.002,381.792,458.21,380,456.002,380z" />
      </g>
      <g>
        <path d="M428.082,180H275.998c-2.208,0-4,1.792-4,4s1.792,4,4,4h152.084c13.188,0,23.916,10.268,23.916,22.888V304 c0,2.208,1.792,4,4,4s4-1.792,4-4v-93.112C459.998,193.856,445.678,180,428.082,180z" />
      </g>
      <g>
        <path d="M428.082,164H287.998c-2.208,0-4,1.792-4,4s1.792,4,4,4h140.084c12.964,0,23.916,10.64,23.916,23.232V284 c0,2.208,1.792,4,4,4s4-1.792,4-4v-88.768C459.998,178.008,445.678,164,428.082,164z" />
      </g>
      <g>
        <path d="M235.998,180H68.026c-12.92,0-23.432-10.512-23.432-23.432c0-2.208-1.792-4-4-4s-4,1.792-4,4 c0,17.332,14.1,31.432,31.432,31.432h167.972c2.208,0,4-1.792,4-4S238.206,180,235.998,180z" />
      </g>
      <g>
        <path d="M405.774,220H68.026c-12.92,0-23.432-10.568-23.432-23.56c0-2.208-1.792-4-4-4s-4,1.792-4,4 c0,17.404,14.1,31.56,31.432,31.56H405.77c1.94,0,2.228,1.632,2.228,2.604V284c0,2.208,1.792,4,4,4s4-1.792,4.004-4v-53.396 C416.002,224.56,411.606,220,405.774,220z" />
      </g>
      <g>
        <path d="M393.79,120h-65.792c-2.208,0-4,1.792-4,4s1.792,4,4,4h65.792c12.66,0,22.208,9.916,22.208,23.064V164 c0,2.208,1.792,4,4,4s4-1.792,4-4v-12.936C423.998,133.644,410.73,120,393.79,120z" />
      </g>
      <g>
        <path d="M187.998,120H68.026c-17.66,0-32.028,13.936-32.028,31.064V424c0,2.208,1.792,4,4,4s4-1.792,4-4V151.064 c0-12.72,10.78-23.064,24.028-23.064h119.972c2.208,0,4-1.792,4-4S190.206,120,187.998,120z" />
      </g>
      <g>
        <path d="M424.598,163.512C422.622,147.828,409.378,136,393.79,136h-81.792c-2.208,0-4,1.792-4,4s1.792,4,4,4h81.792 c11.568,0,21.4,8.82,22.872,20.512c0.256,2.02,1.976,3.5,3.964,3.5c0.168,0,0.336-0.012,0.504-0.032 C423.322,167.704,424.874,165.704,424.598,163.512z" />
      </g>
      <g>
        <path d="M223.998,164H70.666c-5.488,0-9.952-4.488-9.952-10c0-5.512,4.464-10,9.952-10h129.332c2.208,0,4-1.792,4-4s-1.792-4-4-4 H70.666c-9.896,0-17.952,8.076-17.952,18s8.052,18,17.952,18h153.332c2.208,0,4-1.792,4-4S226.206,164,223.998,164z" />
      </g>
      <g>
        <path d="M458.282,300H329.718c-9.772,0-17.72,7.952-17.72,17.724v52.552c0,9.772,7.948,17.724,17.72,17.724h128.56 c9.772,0,17.72-7.952,17.724-17.72v-52.556C476.002,307.952,468.05,300,458.282,300z M468.002,370.276 c0,5.364-4.36,9.724-9.72,9.724H329.718c-5.36,0-9.72-4.36-9.72-9.72v-52.556c0-5.364,4.36-9.724,9.72-9.724h128.564 c5.36,0,9.72,4.364,9.72,9.724V370.276z" />
      </g>
      <g>
        <path d="M356.774,315.728c-15.44,0-28.004,12.56-28.004,28c0,15.444,12.564,28.008,28.004,28.008 c15.444,0,28.004-12.568,28.004-28.008S372.214,315.728,356.774,315.728z M356.774,363.736c-11.028,0-20.004-8.976-20.004-20.008 c0-11.028,8.976-20,20.004-20c11.032,0,20.004,8.972,20.004,20C376.778,354.76,367.802,363.736,356.774,363.736z" />
      </g>
      <g>
        <path d="M364.638,339.732c-2.208,0-4,1.792-4,4c0,2.192-1.78,3.976-3.972,3.976c-2.192,0-3.976-1.784-3.976-3.976 c0-2.192,1.784-3.972,3.976-3.972c2.208,0,4-1.792,4-4s-1.792-4-4-4c-6.604,0-11.976,5.372-11.976,11.972 c0,6.604,5.372,11.976,11.976,11.976c6.6,0,11.972-5.376,11.972-11.976C368.638,341.524,366.846,339.732,364.638,339.732z" />
      </g>
      <g>
        <path d="M412.002,380c-2.208,0-4,1.792-4,4v76.708c0,1.556-1.056,3.292-2.564,3.292H68.026c-12.92,0-23.432-10.612-23.432-23.652 c0-2.208-1.792-4-4-4s-4,1.792-4,4c0,17.452,14.1,31.652,31.432,31.652h337.408c5.824,0,10.564-5.068,10.568-11.292V384 C416.002,381.792,414.21,380,412.002,380z" />
      </g>
      <g>
        <path d="M347.802,98.288c-0.664-1.396-2.068-2.288-3.616-2.288h-32.188V4c0-2.208-1.792-4-4-4h-104c-2.208,0-4,1.792-4,4v92 h-32.084c-1.548,0-2.956,0.892-3.616,2.288c-0.664,1.4-0.46,3.052,0.52,4.248l88.144,107.536c0.76,0.928,1.892,1.464,3.092,1.464 c1.196,0,2.332-0.536,3.092-1.464l88.136-107.536C348.262,101.34,348.466,99.684,347.802,98.288z M256.05,201.224L176.362,104 h27.636c2.208,0,4-1.792,4-4V8h96v92c0,2.208,1.792,4,4,4h27.74L256.05,201.224z" />
      </g>
      <g>
        <path d="M279.998,68c-2.208,0-4,1.792-4,4v60.748L256.25,156.9l-20.252-24.76V36h44c2.208,0,4-1.792,4-4s-1.792-4-4-4h-48 c-2.208,0-4,1.792-4,4v101.568c0,0.924,0.32,1.816,0.904,2.532l24.252,29.652c0.76,0.928,1.896,1.468,3.096,1.468 c1.204,0,2.34-0.54,3.096-1.468l23.748-29.044c0.584-0.712,0.904-1.608,0.904-2.532V72C283.998,69.792,282.206,68,279.998,68z" />
      </g>
    </g>
  </svg>
);
}

export default Import;
