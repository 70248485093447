import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function ErrorAlertBox({show, setShow}) {

  useEffect(()=>{
    const timerID = setTimeout(()=>{
      setShow({
        status:false,
        message:""
      })
    },5000)
    clearTimeout(timerID)
  },[show])

  if (show.status) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <p>
          {show.message}
        </p>
      </Alert>
    );
  }
}

export default ErrorAlertBox;