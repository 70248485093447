import { isAddress } from 'ethers';
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import QrScanner from 'react-qr-scanner';
import TransactionModal from './TransactionModal';
import { globalContext } from '../GlobalStates/globalContext';

function ScanQrModal({showScanner, setShowScanner}) {
  const globalState = useContext(globalContext)
    const [validAddress, setValidAddress] = useState(false)
    const [receiverAddress, setReceiverAddress] = useState()
    const [transactionModalShow, setTransactionModalShow] = useState(false)

    const handleScan = (data) => {
      try{
        if (data) {
            let scanData = data?.text
            if(scanData.startsWith("ethereum:")){
                scanData = scanData.slice(9, scanData.length)
            }
            const validAccountAddress = isAddress(scanData)
            if(validAccountAddress){
                setValidAddress(true)
                setTransactionModalShow(true)
                setShowScanner(false)
                setReceiverAddress(scanData)
                return
            }else if(!validAccountAddress){
              globalState.setShowError({
                status : true,
                message : "not a valid address"
              })
            }
            setTransactionModalShow(false)
            setShowScanner(false)
            setValidAddress(false)
          }
      }catch(error){
        globalState.setShowError({
          status : true,
          message : error.message
        })
      }
    }
    const handleError = (error) => {
      globalState.setShowError({
        status : true,
        message : error.message
      })
    }
  return (
    <div>
        <Modal show={showScanner} onHide={()=>{setShowScanner(false)}} style={{height:"100vh",width:"100vw"}}>
        <Modal.Header closeButton>
          <Modal.Title>Send Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrScanner
          constraints={{
            video: {
              facingMode: { exact: 'environment' },
            }
          }}
          onError={handleError}
          onScan={handleScan}
          style={{width:"100%"}}
        />
        </Modal.Body>
      </Modal>
      {
        validAddress && <TransactionModal transactionModalShow={transactionModalShow} setTransactionModalShow={setTransactionModalShow} receiverAddress={receiverAddress} />
      }
    </div>
  )
}

export default ScanQrModal