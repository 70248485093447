import { ethers, formatEther } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import { globalContext } from "../GlobalStates/globalContext";
import TransactionDetailModal from "./TransactionDetailModal";

const address = "0x2CA54cf64c7a8c9b3a45219376c90e5045AAe37e";

function RecentTransaction({ txhash }) {
  const globalState = useContext(globalContext);
  const [transaction, setTransaction] = useState();
  const [transactionDate, setTransactionDate] = useState();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const getTx = async () => {
      if (globalState.globalProvider) {
        const provider = new ethers.BrowserProvider(globalState.globalProvider);
        try {
          const tx = await provider.getTransaction(txhash);
          const txBlock = await provider.getBlock(tx.blockHash);
          const txDate = new Date(txBlock.timestamp * 1000);
          setTransaction(tx);
          setTransactionDate(txDate.toDateString());
        } catch (error) {
          console.log(error);
        }
      }
    };
    getTx();
  }, [globalState.globalProvider]);

  const getStatus = () => {
    if (transaction) {
      if (transaction.from === address) {
        return {
          icon: <i className="bi bi-arrow-up tx-icon"></i>,
          label: (
            <span style={{ color: "#5C3FD1" }} className="font-weight-bold">
              Send
            </span>
          ),
          secondParty: `To ${transaction.to}`,
        };
      }
      if (transaction.to === address) {
        return {
          icon: <i className="bi bi-arrow-down tx-icon"></i>,
          label: (
            <span style={{ color: "#5C3FD1" }} className="font-weight-bold">
              Receive
            </span>
          ),
          secondParty: `From ${transaction.from}`,
        };
      }
    }
  };

  return (
    <>
      {transaction && transactionDate ? (
        <>
          {" "}
          <div
            onClick={() => {
              setShowDetails(true);
            }}
            className="transaction-item"
          >
            <div className="transaction-details">
              <div className="transaction-icon">{getStatus().icon}</div>
              <div className="transaction-info">
                <div className="transaction-label">{getStatus().label}</div>
                <div className="text-muted">
                  {transactionDate}, {getStatus().secondParty}
                </div>
              </div>
            </div>
            <div className="transaction-amount">
              {formatEther(transaction.value)}ETH
            </div>
          </div>
          {showDetails && <TransactionDetailModal
          transaction={transaction}
          txhash={txhash}
            show={showDetails}
            onHide={() => {
              setShowDetails(false);
            }}
          />}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default RecentTransaction;
