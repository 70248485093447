import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import '../CSS/CreateWallet.css';
import { useNavigate } from 'react-router-dom';

function CreateWallet({ navigateTo, mnemonic }) {
  const [mnemonicWords, setMnemonicWords] = useState([]);

  useEffect(() => {
    const words = mnemonic.split(' ');
    setMnemonicWords(words);
  }, [mnemonic]);

  const handleNext = () => {
    const shuffledPhrase = [...mnemonicWords].sort(() => Math.random() - 0.5);
    navigateTo('ConfirmPhrasePage', shuffledPhrase.join(' ')); // Pass the shuffled mnemonic
  };

  return (
    <div className="create-wallet-container">
      <div className="top-bar">
        <FaArrowLeft onClick={() => navigateTo('ImportWallet')} className="back-icon" />
        <span>Back</span>
      </div>
      <h2>Write down your Secret Recovery Phrase</h2>
      <p>This is your secret recovery phrase. Write it down on a paper and keep it safe.</p>
      <p>You'll be asked to re-enter this phrase (in order) on the next step</p>
      <div className="word-grid">
        {mnemonicWords.map((word, index) => (
          <div key={index} className="input-group">
            <label>{index + 1}. {word}</label>
          </div>
        ))}
      </div>
      <button className='new-wallet-submit-button' onClick={handleNext}>Next</button>
    </div>
  );
}

export default CreateWallet;
