import React, { useContext, useState  } from 'react'
import { globalContext } from '../GlobalStates/globalContext';
import { Modal, Button } from "react-bootstrap";
import QRCode from 'react-qr-code';

function ReceiveModal(props) {
  const globalState = useContext(globalContext)
  const [copyIconClass, setCopyIconClass] = useState("bi-clipboard2")
  return (
    <Modal
      
      show={props.reveiveModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {props.setReceiveModalShow(false);}}
    >
      <Modal.Header closeButton className='no-border'>
        <Modal.Title className='w-100 text-center'>Receive</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {globalState.currentAccountAddress && <QRCode value={`ethereum:${globalState.currentAccountAddress}`} />}
        <p className="account-address text-muted mt-3">{globalState.currentAccountAddress}</p>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(globalState.currentAccountAddress);
            setCopyIconClass("bi-clipboard2-check-fill")
            setTimeout(()=>{
              setCopyIconClass("bi-clipboard2")
            },3000)
          }}
          className="mt-2 btn "
          id="copy-button"
        >
          <i className={`bi ${copyIconClass}`}></i> Copy address
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default ReceiveModal


