import React, { useContext } from 'react'
import { globalContext } from '../GlobalStates/globalContext'
import { useNavigate } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import { web3authInstance } from '../web3auth_config/web3authConfig'
function LogOutModal({logOutModal, setLogOutModal}) {
  const globalState = useContext(globalContext)
  const navigate = useNavigate()
  const handleClose=()=>{
    setLogOutModal(false)
  }
  const handleLogOut = async ()=>{
    const web3auth = await web3authInstance(globalState.selectedNetwork)
    setLogOutModal(false)
    globalState.setUser(null)
    globalState.setIsLoggedIn(false)
    globalState.setGlobalProvider(null)
    globalState.setCurrentAccountAddress(null)
    await globalState.web3auth.logout()
    web3auth.clearCache()
    navigate('/')
  }
  return (
    <Modal  show={logOutModal} onHide={handleClose} backdrop='static' centered >
      <Modal.Body className="text-center">Are you sure? You want to log out</Modal.Body>
      <Modal.Footer className="no-border d-flex justify-content-center">
        <Button onClick={handleClose} id="cancel-logout-button" className="me-3">
          Cancel
        </Button>
        <Button variant="danger" onClick={handleLogOut} className="ms-3">
          Log Out
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LogOutModal