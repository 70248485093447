import React from 'react'
import { useState } from "react"
import { globalContext } from './globalContext'

function GlobalStates({children}) {
    const [currentAccountAddress, setCurrentAccountAddress] = useState()
    const [currentAccountBalance, setCurrentAccountBalance] = useState()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState(null)
    const [globalProvider,setGlobalProvider] = useState(null)
    const [userInfo, setUserInfo] = useState()
    const [selectedNetwork, setSelectedNetwork] = useState("sepolia")
    const [web3auth, setWeb3auth] = useState()
    const [showError, setShowError] = useState({status:false,message:""})

    const states = {
        currentAccountAddress, setCurrentAccountAddress,
        currentAccountBalance, setCurrentAccountBalance,
        isLoggedIn, setIsLoggedIn,
        user, setUser,
        globalProvider,setGlobalProvider,
        userInfo, setUserInfo,
        selectedNetwork, setSelectedNetwork,
        web3auth, setWeb3auth,
        showError, setShowError
      }
  return (
    <globalContext.Provider value={states}>
            {children}
    </globalContext.Provider>
  )
}

export default GlobalStates