import React, { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { globalContext } from "../GlobalStates/globalContext";
import { ethers, isAddress, parseEther } from "ethers";
import { Formik, Form as FormikForm, useField } from "formik";
import * as Yup from "yup";
import TransactionDetailModal from "./TransactionDetailModal";
import AlertBox from "./ErrorAlertBox";
import ErrorAlertBox from "./ErrorAlertBox";

function TransactionModal({
  transactionModalShow,
  setTransactionModalShow,
  receiverAddress,
}) {
  const globalState = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState(false);
  const [showtx, setShowTx] = useState(false);

  const sendTransaction = async (values, { resetForm }) => {
    const provider = new ethers.BrowserProvider(globalState.globalProvider);
    const signer = await provider.getSigner(globalState.currentAccountAddress);
    try {
      setLoading(true);
      const tx = await signer.sendTransaction({
        to: values["receiver-address"],
        value: parseEther(values["send-amount"]),
      });
      setTransactionDetail(tx);
      setShowTx(true);
    } catch (error) {
      globalState.setShowError({
        status : true,
        message : error.message
      })
      console.log(error)
    }
    setLoading(false);
    setTransactionModalShow(false);
    resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          "receiver-address": receiverAddress || "",
          "send-amount": "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          "receiver-address": Yup.string()
            .trim()
            .required("Required field")
            .test("validate-address", "Address is not valid", (value) => {
              return isAddress(value);
            }),
          "send-amount": Yup.number()
            .typeError("Enter a valid amount")
            .positive("Amount must be greater than zero")
            .required("Required field")
            .test("validate-balance", "Insufficient balance", (value) => {
              return value < globalState.currentAccountBalance;
            }),
        })}
        onSubmit={sendTransaction}
      >
        {({ resetForm }) => (
          <>
            
            <Modal
              show={transactionModalShow}
              size="lg"
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                setTransactionModalShow(false);
                resetForm();
              }}
            >
              <Modal.Header closeButton className="no-border">
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  className="w-100 text-center"
                >
                  Send Funds
                </Modal.Title>
              </Modal.Header>
              <FormikForm>
                {loading ? (
                  <h2>Sending...</h2>
                ) : (
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        style={{ backgroundColor: "#F4F5FB", color: "grey" }}
                        type="text"
                        value={globalState.currentAccountAddress}
                        disabled
                      />
                    </Form.Group>
                    <Input
                      label="Receiver's Public Address"
                      id="receiver-address"
                      name="receiver-address"
                      type="text"
                      placeholder="eg 0x"
                      receiverAddress={receiverAddress}
                    />
                    <Input
                      label="Amount"
                      type="text"
                      id="send-amount"
                      name="send-amount"
                      placeholder="0"
                    />
                    <Button id="send-button" type="submit">
                       Send
                    </Button>
                  </Modal.Body>
                )}
              </FormikForm>
            </Modal>
            <TransactionDetailModal
              show={showtx}
              onHide={() => {
                setShowTx(false);
              }}
              transaction={transactionDetail}
            />
          </>
        )}
      </Formik>
    </>
  );
}

const Input = ({ label, type, receiverAddress, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={props.id || props.name}>{label}</Form.Label>
      <Form.Control
      autoComplete="off"
        type={type}
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
        disabled={receiverAddress ? true : false}
      />
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default TransactionModal;
