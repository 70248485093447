import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { globalContext } from '../GlobalStates/globalContext'

function SwitchNetwork() {
    const globalState = useContext(globalContext)
    const [showNetworks, setShowNetworks] = useState(false)

    const switchNetwork = (networkName) => {
        globalState.setSelectedNetwork(networkName)
        setShowNetworks(false)
    }

    
  return (
  <>
    <div onClick={()=>{setShowNetworks(true)}}>{globalState.globalProvider.chainConfig.displayName} <i className='bi bi-caret-down'></i></div>
    <Modal
      
      show={showNetworks}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {setShowNetworks(false)}}
    >
      <Modal.Header closeButton className='no-border'>
        <Modal.Title className='w-100 text-center'>Receive</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <button onClick={()=>{switchNetwork("sepolia")}}>Sepolia</button>
        <button onClick={()=>{switchNetwork("polygon")}}>Polygon</button>
        <button onClick={()=>{switchNetwork("mainnet")}}>Mainnet</button>
        <button onClick={()=>{switchNetwork("amoy")}}>Amoy</button>
        <button onClick={()=>{switchNetwork("holesky")}}>Holesky</button>
      </Modal.Body>
    </Modal>
  </>
  )
}

export default SwitchNetwork