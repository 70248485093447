import React, { useEffect, useState } from 'react';
import ImportWallet from './ImportWallet';
import CreateWallet from './CreateWallet';
import ConfirmPhrasePage from './ConfirmPhrasePage';
import { useNavigate } from 'react-router-dom';

function App() {
  const [currentPage, setCurrentPage] = useState('ImportWallet');
  const [mnemonic, setMnemonic] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth_store = JSON.parse(localStorage.getItem("auth_store"));
    const sessionId = auth_store?.sessionId
    if(!sessionId){
      navigate('/')
    }
  }, []);

  
  const navigateTo = (page, mnemonicData = '') => {
    setCurrentPage(page);
    if (mnemonicData) {
      setMnemonic(mnemonicData); 
    }
  };

  return (
    <div className="app-container">
      {currentPage === 'ImportWallet' && <ImportWallet navigateTo={navigateTo} />}
      {currentPage === 'CreateWallet' && <CreateWallet navigateTo={navigateTo} mnemonic={mnemonic} />}
      {currentPage === 'ConfirmPhrasePage' && <ConfirmPhrasePage navigateTo={navigateTo} mnemonic={mnemonic} />}
    </div>
  );
}

export default App;
