import React, { useContext, useEffect, useState } from "react";
import { globalContext } from "../GlobalStates/globalContext";
import { Modal, Button } from "react-bootstrap";
import QRCode from "react-qr-code";
import { ethers, formatEther } from "ethers";

function TransactionDetailModal({ show, onHide, txhash, transaction }) {
  const globalState = useContext(globalContext);
  const [copyIconClass, setCopyIconClass] = useState("bi-clipboard2");
  const [transactionDate, setTransactionDate] = useState();


  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton className="no-border">
        <Modal.Title className="w-100 text-center">
          Transaction Detail
        </Modal.Title>
      </Modal.Header>
      {transaction && (
        <Modal.Body className="text-center">
          <p><span>Transaction ID :</span><span> {transaction.hash}</span></p>
          <p><span>From :</span><span> {transaction.from}</span></p>
          <p><span>To :</span><span> {transaction.to}</span></p>
          <p><span>Amount :</span><span> {formatEther(transaction.value)}</span></p>
          <p><span>Gas Limit :</span><span> {formatEther(transaction.gasLimit)}</span></p>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default TransactionDetailModal;
